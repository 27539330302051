import { Component } from '@angular/core';
import {IDevise} from "../../../../../core/models/devise.model";
import {CustomDialogData} from "../../../../../core/models/custom-dialog.model";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {IAccount} from "../../../../../core/models/account.model";
import {VirementService} from "../../../../../core/services/virement.service";
import {DeviseService} from "../../../../../core/services/devise.service";
import {ToastService} from "../../../../../core/services/toast.service";
import {AccountService} from "../../../../../core/services/account.service";
import {TimelineModule} from "primeng/timeline";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Button} from "primeng/button";
import {CalendarModule} from "primeng/calendar";
import {DropdownModule} from "primeng/dropdown";
import {InputNumberModule} from "primeng/inputnumber";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {NgIf} from "@angular/common";
import {Ripple} from "primeng/ripple";

@Component({
  selector: 'app-view-virement',
  standalone: true,
  imports: [
    TimelineModule,
    Button,
    CalendarModule,
    DropdownModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    NgIf,
    ReactiveFormsModule,
    Ripple
  ],
  templateUrl: './view-virement.component.html',
  styleUrl: './view-virement.component.scss'
})
export class ViewVirementComponent {

  formGroup = new FormGroup({
    libelle: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    devise: new FormControl<IDevise | null>(null, {nonNullable: true, validators: [Validators.required]}),
    description: new FormControl<string>(''),
    amount: new FormControl<number>(1, { nonNullable: true, validators: [Validators.required] }),
    operationDate: new FormControl<Date>(new Date(), { nonNullable: true, validators: [Validators.required] }),
    valueDate: new FormControl<Date>(new Date(), { nonNullable: true, validators: [Validators.required] }),
    creditAccount: new FormControl<IAccount>({} as IAccount, { nonNullable: true, validators: [Validators.required] }),
    debitAccount: new FormControl<IAccount>({} as IAccount, { nonNullable: true, validators: [Validators.required] }),
  });
  boundData: CustomDialogData | undefined;
  isLoading: boolean = false;
  instance: DynamicDialogComponent | undefined;

  constructor(private dialogService: DialogService, private ref: DynamicDialogRef) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    this.boundData = this.instance?.data;
    this.formGroup.patchValue(this.boundData?.data);
    this.formGroup.patchValue({
      creditAccount: this.boundData?.data.creditAccount,
      debitAccount: this.boundData?.data.debitAccount,
    });
    this.formGroup.disable();
    console.log('Data:', this.boundData);
  }

}
