import { Component, OnInit, OnDestroy } from '@angular/core';
import { CustomAction, CustomTableHeader } from "../../../../core/models/custom-table.model";
import { getDefaultPageable, Pageable, PageParams } from "../../../../core/models/page.model";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ToastService } from "../../../../core/services/toast.service";
import { StorageService } from "../../../../core/services/storage.service";
import { customDynamicDialog, DEFAULT_ROWS_PER_PAGE_OPTIONS } from "../../../../core/utils/utils";
import { CustomDialogData } from "../../../../core/models/custom-dialog.model";
import { take } from "rxjs";
import { PaginatorState } from "primeng/paginator";
import { BeneficiaryService } from "../../../../core/services/beneficiary.service";  // Assuming you have this service
import { EditBeneficiaryComponent } from "./edit-beneficiary/edit-beneficiary.component";  // Placeholder components
import { DynamicTableComponent } from "../../../../shared/components/dynamic-table/dynamic-table.component";
import { ToolbarComponent } from "../../../../shared/components/toolbar/toolbar.component";
import {IBeneficiary} from "../../../../core/models/beneficiary.model";

@Component({
  selector: 'app-beneficiary',
  standalone: true,
  imports: [
    DynamicTableComponent,
    ToolbarComponent
  ],
  templateUrl: './beneficiary.component.html',
  styleUrls: ['./beneficiary.component.scss']
})
export class BeneficiaryComponent implements OnInit, OnDestroy {

  beneficiaries: IBeneficiary[] = [];
  columns: CustomTableHeader[] = [
    { key: 'name', column: 'Nom', type: 'text' },
    { key: 'bank', column: 'Banque', type: 'text' },
    { key: 'bic', column: 'BIC', type: 'text' },
    { key: 'accountNumber', column: 'Numéro de compte', type: 'text' },
    { key: 'iban', column: 'IBAN', type: 'text' },
  ];
  pageable: PageParams = { page: 0, first: 0, rows: 0, totalRecords: 0, rowPerPageOptions: [] };
  ref: DynamicDialogRef | undefined;
  actions: CustomAction[] = [];

  constructor(private beneficiaryService: BeneficiaryService,
              private toastService: ToastService,
              public dialogService: DialogService,
              private tokenManager: StorageService) {}

  ngOnInit() {
    this.loadBeneficiaries();
    this.actions = this.getActions();
  }

  ngOnDestroy() {
    this.ref?.close();
  }

  getActions(): CustomAction[] {
    return [
      {
        role: 'READ_BENEFICIARY',
        icon: 'pi pi-info-circle',
        label: 'Consulter',
        severity: 'info',
        emit: (item) => this.viewBeneficiary(item),
        visible: true
      },
      {
        role: 'UPDATE_BENEFICIARY',
        icon: 'pi pi-pen-to-square',
        label: 'Editer',
        severity: 'primary',
        emit: (item) => this.editBeneficiary(item),
        visible: true
      },
      {
        role: 'DELETE_BENEFICIARY',
        icon: 'pi pi-trash',
        label: 'Supprimer',
        severity: 'danger',
        emit: (item) => this.deleteBeneficiary(item),
        visible: true
      },
    ];
  }

  loadBeneficiaries(pageable: Pageable = getDefaultPageable()) {
    const jwt = this.tokenManager.getToken();
    this.beneficiaryService.getBeneficiaryByInstitutionId(jwt.institution_id, pageable).subscribe({
      next: page => {
        this.beneficiaries = page.content;
        this.pageable = {
          page: page.number,
          first: page.number * page.size,
          rows: page.size,
          totalRecords: page.totalElements,
          rowPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS
        }
      },
      error: err => {
        this.toastService.showToast('Erreur lors du chargement des bénéficiaires', err, 'error');
      }
    });
  }

  viewBeneficiary(beneficiary: IBeneficiary) {
    const data = { isView: true, data: beneficiary } as CustomDialogData;
    this.ref = this.dialogService.open(EditBeneficiaryComponent, customDynamicDialog('Détails bénéficiaire', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadBeneficiaries());
  }

  editBeneficiary(beneficiary: IBeneficiary) {
    const data = { isEdit: true, data: beneficiary } as CustomDialogData;
    this.ref = this.dialogService.open(EditBeneficiaryComponent, customDynamicDialog('Modification bénéficiaire', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadBeneficiaries());
  }

  createBeneficiary() {
    const data = { isCreate: true } as CustomDialogData;
    this.ref = this.dialogService.open(EditBeneficiaryComponent, customDynamicDialog('Création d\'un bénéficiaire', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadBeneficiaries());
  }

  deleteBeneficiary(beneficiary: IBeneficiary) {
    this.beneficiaryService.deleteBeneficiary(beneficiary.id!).subscribe({
      next: () => {
        this.toastService.showToast('Bénéficiaire supprimé', `Le bénéficiaire ${beneficiary.name} a été supprimé avec succès.`);
        this.loadBeneficiaries();
      },
      error: err => {
        this.toastService.showToast('Erreur lors de la suppression du bénéficiaire', err, 'error');
      }
    });
  }

  onPageChange(event: PaginatorState) {
    this.loadBeneficiaries(getDefaultPageable(event.page, event.rows));
  }
}
