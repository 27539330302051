import { Component } from '@angular/core';
import {Button} from "primeng/button";
import {StepperModule} from "primeng/stepper";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {InputOtpModule} from "primeng/inputotp";
import {AuthService} from "../../core/services/auth.service";
import {ToastService} from "../../core/services/toast.service";
import {ToggleButtonModule} from "primeng/togglebutton";
import {NgClass} from "@angular/common";
import {PasswordModule} from "primeng/password";
import {InputIconModule} from "primeng/inputicon";
import {IconFieldModule} from "primeng/iconfield";
import {Ripple} from "primeng/ripple";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    // Modules
    Button, StepperModule, FormsModule, InputTextModule, InputOtpModule, ToggleButtonModule, PasswordModule,
    InputIconModule, IconFieldModule,
    // Directives & Pipes
    NgClass, Ripple, RouterLink
  ],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent {

  email: string = '';
  code: string = '';
  active: number = 0;
  emailVerified: boolean = false;
  isCodeValid: boolean = false;

  constructor(private authService: AuthService, private toastService: ToastService) {}

  resetPassword() {
    this.authService.resetPassword(this.code, this.email).subscribe({
      next: () => {
        const summary = 'Mot de passe réinitialisé';
        const detail = 'Votre mot de passe a été réinitialisé avec succès. Vous recevrez un email avec votre nouveau mot de passe.';
        this.toastService.showToast(summary, detail);
      },
      error: () => {
        const detail = 'Impossible de réinitialiser le mot de passe. Veuillez vérifier votre adresse email.';
        this.toastService.showToast('Erreur lors de la validation d\'identité', detail, 'error')
      }
    });
  }

  requestPasswordReset() {
    this.authService.initPasswordReset(this.email).subscribe({
      next: () => {
        const detail = 'Un email de réinitialisation de mot de passe a été envoyé à votre adresse email';
        this.toastService.showToast('Email envoyé', detail);
        this.active = 1;
      },
      error: (err) => this.toastService.showToast('Erreur', err.error, 'error')
    });
  }

  checkEmailValidity(email: string) {
    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    this.emailVerified = emailPattern.test(email);
  }
}
