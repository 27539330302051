import {Component, OnInit, ViewChild} from '@angular/core';
import {AvatarModule} from "primeng/avatar";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {Sidebar, SidebarModule} from "primeng/sidebar";
import {Ripple} from "primeng/ripple";
import {StyleClassModule} from "primeng/styleclass";
import {HasRoleDirective} from "../../../core/directives/has-role.directive";
import {StorageService} from "../../../core/services/storage.service";
import {HasModuleDirective} from "../../../core/directives/has-module.directive";
import {HasProductDirective} from "../../../core/directives/has-product.directive";

@Component({
  selector: 'sidebar-menu',
  standalone: true,
  imports: [
    AvatarModule,
    SidebarModule,
    Ripple,
    StyleClassModule,
    HasRoleDirective,
    RouterLink,
    HasModuleDirective,
    HasProductDirective
  ],
  templateUrl: './sidebar-menu.component.html',
  styleUrl: './sidebar-menu.component.scss'
})
export class SidebarMenuComponent implements OnInit {

  @ViewChild('sidebarRef') sidebarRef!: Sidebar;
  username: string = '';
  institution: string = '';

  constructor(private router: Router, private route: ActivatedRoute, private tokenManager: StorageService) {}

  ngOnInit() {
    const jwt = this.tokenManager.getToken();
    if(jwt) {
      this.username = jwt.sub;
      this.institution = jwt.institution;
    }
  }

  async navigateTo(route: string) {
    await this.router.navigate([route], {relativeTo: this.route});
  }


  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    this.router.navigateByUrl('/login').finally();
  }

}
