import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IBeneficiary} from "../models/beneficiary.model";
import {getPageableAsParams, Page, Pageable} from "../models/page.model";

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryService {

  private baseUrl = 'operation/v1/beneficiary'; // Base URL for the API

  constructor(private http: HttpClient) { }

  /**
   * Create a new beneficiary.
   *
   * @param beneficiary The beneficiary data.
   * @returns An Observable of the created IBeneficiary.
   */
  createBeneficiary(beneficiary: IBeneficiary): Observable<IBeneficiary> {
    return this.http.post<IBeneficiary>(this.baseUrl, beneficiary);
  }

  /**
   * Update an existing beneficiary.
   *
   * @param id The ID of the beneficiary to update.
   * @param beneficiary The updated beneficiary data.
   * @returns An Observable of the updated IBeneficiary.
   */
  updateBeneficiary(id: string, beneficiary: IBeneficiary): Observable<IBeneficiary> {
    return this.http.put<IBeneficiary>(`${this.baseUrl}/${id}`, beneficiary);
  }

  /**
   * Get a beneficiary by its ID.
   *
   * @param id The ID of the beneficiary.
   * @returns An Observable of the IBeneficiary.
   */
  getBeneficiaryById(id: string): Observable<IBeneficiary> {
    return this.http.get<IBeneficiary>(`${this.baseUrl}/${id}`);
  }

  /**
   * Get a beneficiary by institution ID.
   *
   * @param institutionId The ID of the institution.
   * @returns An Observable of the IBeneficiary.
   */
  getBeneficiaryByInstitutionId(institutionId: string, pageable: Pageable): Observable<Page<IBeneficiary>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IBeneficiary>>(`${this.baseUrl}/institution/${institutionId}`, { params });
  }

  /**
   * Get all beneficiaries with optional pagination.
   *
   * @param pageable An object representing pagination info (e.g., page number, size, sort).
   * @returns An Observable of a paginated list of beneficiaries.
   */
  getAllBeneficiaries(pageable: Pageable): Observable<Page<IBeneficiary>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IBeneficiary>>(this.baseUrl, { params });
  }

  /**
   * Delete a beneficiary by its ID.
   *
   * @param id The ID of the beneficiary to delete.
   * @returns An Observable of void.
   */
  deleteBeneficiary(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
}
