import { Injectable } from '@angular/core';
import * as forge from 'node-forge';
import {StorageService} from "./storage.service";

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  constructor(private storageService: StorageService) {}

  public encryptData(data: string): string {
    // Get the public key from local storage
    const publicKeyPem = this.storageService.getPublicKey();
    if (!publicKeyPem) throw new Error('Public key not found in local storage');

    // Convert the public key from PEM format to a forge public key object
    const publicKey = forge.pki.publicKeyFromPem(
      `-----BEGIN PUBLIC KEY-----\n${publicKeyPem}\n-----END PUBLIC KEY-----`
    );

    // Encrypt the data using the public key
    const encryptedData = publicKey.encrypt(data, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
      mgf1: {
        md: forge.md.sha1.create(),
      },
    });

    // Encode the encrypted data in base64
    return forge.util.encode64(encryptedData);
  }
}
