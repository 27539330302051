import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {IDevise} from "../models/devise.model";
import {getPageableAsParams, Page, Pageable} from "../models/page.model";

@Injectable({
  providedIn: 'root',
})
export class DeviseService {
  private readonly apiUrl = 'operation/v1/devises';

  constructor(private http: HttpClient) {}

  /**
   * Get all devises with optional pagination and sorting.
   * @param pageable The page number (0-based index).
   * @returns An observable of a page of IDevises.
   */
  getAllDevises(pageable: Pageable): Observable<Page<IDevise>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IDevise>>(this.apiUrl, { params });
  }

  /**
   * Get a specific devise by its code.
   * @param code The code of the devise.
   * @returns An observable of the IDevise.
   */
  getDeviseByCode(code: string): Observable<IDevise> {
    let params = new HttpParams().set('code', code);
    return this.http.get<IDevise>(`${this.apiUrl}/code`, { params });
  }

  /**
   * Create a new devise.
   * @param deviseDTO The IDevise object.
   * @returns An observable of the created IDevise.
   */
  createDevise(deviseDTO: IDevise): Observable<IDevise> {
    return this.http.post<IDevise>(this.apiUrl, deviseDTO);
  }

  /**
   * Update an existing devise by its code.
   * @param code The code of the devise to update.
   * @param deviseDTO The updated IDevise object.
   * @returns An observable of the updated IDevise.
   */
  updateDevise(code: string, deviseDTO: IDevise): Observable<IDevise> {
    let params = new HttpParams().set('code', code);
    return this.http.put<IDevise>(this.apiUrl, deviseDTO, { params });
  }

  /**
   * Delete (soft delete) a devise by its code.
   * @param code The code of the devise to delete.
   * @returns An observable of void.
   */
  deleteDevise(code: string): Observable<void> {
    let params = new HttpParams().set('code', code);
    return this.http.delete<void>(this.apiUrl, { params });
  }
}
