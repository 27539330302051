import {Component} from '@angular/core';
import {TabMenuModule} from "primeng/tabmenu";
import {MenuItem} from "primeng/api";

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [ TabMenuModule ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent {

  items: MenuItem[] = [
    {
      label: 'Profil',
      icon: 'pi pi-user-edit',
      routerLink: 'info'
    },
    {
      label: 'Sécurité',
      icon: 'pi pi-shield',
      routerLink: 'security'
    },
  ];

}
