import { Component } from '@angular/core';
import {PrimeTemplate} from "primeng/api";
import {TimelineModule} from "primeng/timeline";
import {CustomDialogData} from "../../../../../core/models/custom-dialog.model";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {DatePipe} from "@angular/common";
import {IAuditVirement, IVirementResponse} from "../../../../../core/models/virement.model";

@Component({
  selector: 'app-audit-virement',
  standalone: true,
  imports: [PrimeTemplate, TimelineModule, DatePipe],
  templateUrl: './audit-virement.component.html',
  styleUrl: './audit-virement.component.scss'
})
export class AuditVirementComponent {

  boundData: CustomDialogData | undefined;
  isLoading: boolean = false;
  instance: DynamicDialogComponent | undefined;
  audits: IAuditVirement[] = [];

  constructor(private dialogService: DialogService, private ref: DynamicDialogRef) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    this.boundData = this.instance?.data;
    this.audits = (this.boundData?.data as IVirementResponse)?.audits?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) || [];
  }

}
