import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Button} from "primeng/button";
import {ToolbarModule} from "primeng/toolbar";
import {InputTextModule} from "primeng/inputtext";
import {NgIf} from "@angular/common";

@Component({
  selector: 'toolbar',
  standalone: true,
  imports: [Button, ToolbarModule, InputTextModule, NgIf],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss'
})
export class ToolbarComponent {

  @Input({required: true}) title: string = '';
  @Output() create = new EventEmitter<any>();
  @Output() search = new EventEmitter<string>();
  @Output() export = new EventEmitter<any>();

}
