import { Component } from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {CustomDialogData} from "../../../../../core/models/custom-dialog.model";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {BeneficiaryService} from "../../../../../core/services/beneficiary.service";
import {ToastService} from "../../../../../core/services/toast.service";
import {IBeneficiary} from "../../../../../core/models/beneficiary.model";
import {Button} from "primeng/button";
import {CalendarModule} from "primeng/calendar";
import {DropdownModule} from "primeng/dropdown";
import {InputNumberModule} from "primeng/inputnumber";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {NgIf} from "@angular/common";
import {PrimeTemplate} from "primeng/api";
import {Ripple} from "primeng/ripple";

@Component({
  selector: 'app-edit-beneficiary',
  standalone: true,
  imports: [
    CalendarModule, DropdownModule, InputNumberModule, InputTextModule, InputTextareaModule, PrimeTemplate, ReactiveFormsModule,
    Button, NgIf, Ripple
  ],
  templateUrl: './edit-beneficiary.component.html',
  styleUrl: './edit-beneficiary.component.scss'
})
export class EditBeneficiaryComponent {

  formGroup = new FormGroup({
    name: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    accountNumber: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    bank: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    agency: new FormControl(''),
    iban: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    bic: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    rib: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
  });

  boundData: CustomDialogData | undefined;
  isLoading: boolean = false;
  instance: DynamicDialogComponent | undefined;

  constructor(private beneficiaryService: BeneficiaryService, private dialogService: DialogService,
              private toastService: ToastService, private ref: DynamicDialogRef) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    this.boundData = this.instance?.data;
    if (!this.boundData?.isCreate) {
      this.formGroup.patchValue(this.boundData?.data as IBeneficiary);
      if (this.boundData?.isView) this.formGroup.disable();
    }
  }

  create() {
    this.isLoading = true;
    const iBeneficiary = this.formGroup.value as IBeneficiary;
    this.beneficiaryService.createBeneficiary(iBeneficiary).subscribe({
      next: (beneficiary) => {
        this.toastService.showToast('Bénéficiaire créé', `Le bénéficiaire ${beneficiary.name} a été créé avec succès.`);
        this.isLoading = false;
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast('Erreur lors de la création du bénéficiaire', error, 'error');
        this.isLoading = false;
        this.ref.close();
      }
    });
  }

  update() {
    this.isLoading = true;
    const iBeneficiary = this.formGroup.value as IBeneficiary;
    this.beneficiaryService.updateBeneficiary(this.boundData?.data.id, iBeneficiary).subscribe({
      next: (beneficiary) => {
        this.toastService.showToast('Bénéficiaire modifié', `Le bénéficiaire ${beneficiary.name} a été modifié avec succès.`);
        this.isLoading = false;
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast('Erreur lors de la modification du bénéficiaire', error);
        this.isLoading = false;
        this.ref.close();
      }
    });
  }

}
