// has-role.directive.ts
import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {StorageService} from "../services/storage.service";

@Directive({
  selector: '[hasRole]',
  standalone: true
})
export class HasRoleDirective implements OnInit {

  @Input({alias: 'hasRole', required: true}) role!: string;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private tokenManager: StorageService) {}

  ngOnInit() {
    const token = this.tokenManager.getToken();
    if (token != null && token.authorities.includes(this.role)) this.viewContainer.createEmbeddedView(this.templateRef);
    else this.viewContainer.clear();
  }

}
