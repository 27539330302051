import { Routes } from '@angular/router';
import {MainComponent} from "./pages/main/main.component";
import {LoginComponent} from "./pages/login/login.component";

import {InstitutionComponent} from "./components/institution/institution.component";
import {UserComponent} from "./components/user/user.component";
import {GroupComponent} from "./components/group/group.component";
import {ResetPasswordComponent} from "./pages/reset-password/reset-password.component";
import {ProfileComponent} from "./components/profile/profile.component";
import {UserInfoComponent} from "./components/profile/user-info/user-info.component";
import {UpdatePasswordComponent} from "./components/profile/update-password/update-password.component";
import {ValidationComponent} from "./components/validation/validation.component";
import {EditValidationComponent} from "./components/validation/edit-validation/edit-validation.component";
import {NotFoundComponent} from "./shared/not-found/not-found.component";
import {VirementComponent} from "./components/tresorerie/correspondant/virement/virement.component";
import {SuiviComponent} from "./components/tresorerie/correspondant/suivi/suivi.component";
import {BeneficiaryComponent} from "./components/tresorerie/correspondant/beneficiary/beneficiary.component";

export const profileRoutes: Routes = [
  { path: 'info', component: UserInfoComponent },
  { path: 'security', component: UpdatePasswordComponent },
  { path: '', redirectTo: 'info', pathMatch: 'full' }
]

export const validationRoutes: Routes = [
  { path: '', component: ValidationComponent },
  { path: 'edit', component: EditValidationComponent }
];

export const tresorerieRoutes: Routes = [
  {
    path: 'locale',
    children: [
      {
        path: 'releve', component: NotFoundComponent
      },
      {
        path: 'previsionnel', component: NotFoundComponent
      },
      {
        path: 'loan', component: NotFoundComponent
      }
    ]
  },
  {
    path: 'devise',
    children: [
      {
        path: 'releve', component: NotFoundComponent
      },
      {
        path: 'previsionnel', component: NotFoundComponent
      },
      {
        path: 'loan', component: NotFoundComponent
      }
    ]
  },
  {
    path: 'correspondant',
    children: [
      {
        path: 'suivi', component: SuiviComponent
      },
      {
        path: 'virement', component: VirementComponent
      },
      {
        path: 'beneficiary', component: BeneficiaryComponent
      }
    ]
  }
];

export const mainRoutes: Routes = [
  { path: 'validation', component: ValidationComponent, children: validationRoutes },
  { path: 'institution', component: InstitutionComponent },
  { path: 'user', component: UserComponent },
  { path: 'group', component: GroupComponent },
  { path: 'profile', component: ProfileComponent, children: profileRoutes },
  { path: 'tresorerie', children: tresorerieRoutes },
  { path: '', redirectTo: 'user', pathMatch: 'full' }
];

export const adminRoutes: Routes = [
  { path: 'profile', component: ProfileComponent, children: profileRoutes },
  { path: 'institution', component: InstitutionComponent },
  { path: 'user', component: UserComponent },
  { path: 'group', component: GroupComponent },
  { path: '', redirectTo: 'user', pathMatch: 'full' }
]

export const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'main', component: MainComponent, children: mainRoutes },
  { path: 'admin', component: MainComponent, children: adminRoutes },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
];
