// has-product.directive.ts
import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { StorageService } from '../services/storage.service';
import {JwtToken} from "../models/jwt.model";
import {modules} from "../models/module.model";

@Directive({
  selector: '[hasProduct]',
  standalone: true
})
export class HasProductDirective implements OnInit {

  @Input('hasProduct') product!: string;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private tokenManager: StorageService) {}

  ngOnInit() {
    const token = this.tokenManager.getToken();
    token != null ? this.checkForProduct(this.product, token) : this.viewContainer.clear();
  }

  checkForProduct(product: string, token: JwtToken) {
    const hasPermission = modules.some(module =>
      module.products.some(prod =>
        prod.product === this.product &&
        prod.permissions.some(permission => token.authorities.includes(permission))
      )
    );

    hasPermission ? this.viewContainer.createEmbeddedView(this.templateRef) : this.viewContainer.clear();
  }
}
