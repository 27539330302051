// product-dto.model.ts

import {IProduct} from "./product.model";

export interface IModule {
  id: string; // UUID can be represented as a string
  code: string;
  description: string;
  enabled: boolean;
  deleted: boolean;
  products: IProduct[];
}

export interface Modules {
  module: string;
  products: Products[];
}

export interface Products {
  product: string;
  permissions: string[];
}

export const modules: Modules[] = [
  {
    module: "ADMINISTRATION",
    products: [
      {
        product: "ADMINISTRATION",
        permissions: [
          "CREATE_USER",
          "UPDATE_USER",
          "DELETE_USER",
          "READ_USER",
          "CREATE_INSTITUTION",
          "UPDATE_INSTITUTION",
          "DELETE_INSTITUTION",
          "READ_INSTITUTION",
          "CREATE_GROUP",
          "UPDATE_GROUP",
          "DELETE_GROUP",
          "READ_GROUP",
          "CREATE_VALIDATION",
          "UPDATE_VALIDATION",
          "DELETE_VALIDATION",
          "READ_VALIDATION"
        ]
      }
    ]
  },
  {
    module: "TRESORERIE",
    products: [
      {
        product: "VIREMENT",
        permissions: [
          "CREATE_VIREMENT",
          "UPDATE_VIREMENT",
          "DELETE_VIREMENT",
          "READ_VIREMENT"
        ]
      }
    ]
  },
  {
    module: "FOREX",
    products: [] // Empty array since there are no products
  },
  {
    module: "RISK_MANAGEMENT",
    products: [] // Empty array since there are no products
  }
];

