<div class="self-center">
  <div class="flex flex-column gap-2 align-items-center">
    <div class="logo flex align-items-baseline gap-2">
      <div class="text-6xl font-bold">alima</div>
      <div class="font-light text-color-secondary">by finappli</div>
    </div>
  </div>
  <div>
    <form [formGroup]="login" (submit)="onSubmit()" *ngIf="!requiresTwoFactor">
      <div class="flex flex-column gap-2">
        <label for="username">Username</label>
        <input pInputText id="username" aria-describedby="username-help" formControlName="username" />
      </div>
      <div class="flex flex-column gap-2">
        <label for="password">Password</label>
        <p-password class="w-full" [feedback]="false"  [toggleMask]="true" formControlName="password" id="password" required></p-password>
      </div>
      <div class="flex justify-content-end w-full">
        <a routerLink="/reset-password" pRipple class="my-2 p-0 w-fit text-sm"> Mot de passe oublié </a>
      </div>
      <p-button [style]="{width: '100%'}" pRipple label="Connexion" [disabled]="!login.valid" [loading]="isLoading" (onClick)="onSubmit()"></p-button>
    </form>
    <form [formGroup]="twoFactorForm" (submit)="onSubmitTwoFactor()" *ngIf="requiresTwoFactor">
      <p-inputOtp [integerOnly]="true" [length]="6" [minlength]="6" formControlName="code" />
      <p-button [style]="{width: '100%'}" [loading]="isLoading" [disabled]="twoFactorForm.invalid" (onClick)="onSubmitTwoFactor()" label="Envoyer"></p-button>
    </form>
  </div>
  <div class="flex gap-2">
    <span> © Finappli SAS, 2024</span>
  </div>
</div>
