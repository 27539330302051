<div class="card h-screen w-screen flex flex-column justify-content-center align-items-center">
  <div class="flex flex-column gap-2 align-items-center">
    <div class="logo flex align-items-baseline gap-2">
      <div class="text-6xl font-bold">alima</div>
    </div>
    <span class="text-color-secondary font-light">Portail d'administration</span>
  </div>
  <div class="text-2xl text-color-secondary my-4 font-semibold">Réinitialisation de mot de passe</div>
  <p-stepper class="w-4" [(activeStep)]="active" [linear]="true">
    <p-stepperPanel>
      <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
        <p-button class="w-3rem h-3rem" icon="pi pi-at" (onClick)="onClick.emit()" [severity]="index == active ? 'primary' : index < active ? 'primary' : 'secondary'" [outlined]="index != active"/>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="flex flex-column gap-2 mx-auto">
          <div class="text-center mt-3 mb-3 text-xl font-semibold">Renseigner votre adresse email</div>
          <div class="field p-fluid">
            <p-iconField>
              <p-inputIcon>
                <i class="pi pi-envelope"></i>
              </p-inputIcon>
              <input [(ngModel)]="email" (ngModelChange)="checkEmailValidity($event)" pInputText id="email1" type="email" placeholder="Email" />
            </p-iconField>
          </div>
        </div>
        <div class="flex pt-2 justify-content-center">
          <p-button (onClick)="requestPasswordReset()" [disabled]="!emailVerified" label="Envoyer" icon="pi pi-arrow-right" iconPos="right" />
        </div>
      </ng-template>
    </p-stepperPanel>
    <p-stepperPanel>
      <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
        <p-button class="w-3rem h-3rem" icon="pi pi-verified" (onClick)="onClick.emit()" [severity]="index == active ? 'primary' : index < active ? 'primary' : 'secondary'" [outlined]="index != active"/>
      </ng-template>
      <ng-template pTemplate="content" let-prevCallback="prevCallback">
        <div class="flex flex-column gap-2 mx-auto" style="min-height: 16rem; max-width: 24rem">
          <div class="text-center mt-3 mb-3 text-xl font-semibold">Code de vérification</div>
          <div class="flex flex-wrap justify-content-center gap-3">
            <p-inputOtp [integerOnly]="true" [length]="6" [minlength]="6" [(ngModel)]="code" />
          </div>
        </div>
        <div class="flex pt-4 justify-content-between">
          <p-button (onClick)="prevCallback.emit()" label="Back" severity="secondary" icon="pi pi-arrow-left" />
          <p-button [disabled]="isCodeValid" (onClick)="resetPassword()" label="Réinitialiser" icon="pi pi-arrow-right" iconPos="right" />
        </div>
      </ng-template>
    </p-stepperPanel>
  </p-stepper>
  <div class="flex justify-content-center w-full">
    <a routerLink="/login" pRipple class="my-2 p-0 w-fit text-sm"> Retourner vers la page d'authentification </a>
  </div>
</div>


